/**
 * Defines the permissions possible for Case Appeals
 */
import { IsResourceOwner } from "./ResourceOwner.js";
/********************************************
 * NOTE:
 *
 * We intentionally do `FN: <X extends Y>(arg: X)`
 * instead of `FN: (ARG: Y)` because it improves
 * the inferred type better for some reason.
 *******************************************/
/**
 * Permissions specific to Cases
 */
export const APPEAL = {
    /**
     * Permission to anything appeal related
     */
    ANY: () => "modules.appeals.**",
    /**
     * Permission to read appeal of a type of case
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param caseOwner Whether the user was the one that created the original case
     */
    READ: (caseType, isAssigned, caseOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.read`;
    },
    /**
     * Permission to update the state (Except verdict) for an appeal
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param caseOwner Whether the user was the one that created the original case
     */
    STATE: (caseType, isAssigned, caseOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.update.state`;
    },
    /**
     * Permission to conclude the appeal by giving it a verdict (setting state to Approved, Rejected, or Case Archived)
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param caseOwner Whether the user was the one that created the original case
     */
    VERDICT: (caseType, isAssigned, caseOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.verdict.create`;
    },
    /**
     * Related to assignment of staff to this case appeal
     */
    ASSIGNMENT: {
        /**
         * Permission to anything appeal Assignment related, of any type of appeal, whether user is assigned or not
         */
        ANY: () => "modules.appeals.*.assignment.**",
        /**
         * Permission to assign a user to this case appeal
         * @param caseType The type of case
         * @param caseOwner Whether the user was the one that created the original case
         */
        CREATE: (caseType, caseOwner) => {
            return `modules.appeals.${caseType}.case.${IsResourceOwner(caseOwner)}.assignment.create`;
        },
        /**
         * Permission to remove assigned of a user from this case appeal
         * @param caseType The type of case
         * @param caseOwner Whether the user was the one that created the original case
         */
        DELETE: (caseType, caseOwner) => {
            return `modules.appeals.${caseType}.case.${IsResourceOwner(caseOwner)}.assignment.delete`;
        },
    },
    /**
     * Related to locking of the case appeal
     */
    LOCK: {
        /**
         * Permission to anything appeal locking related, of any type of appeal, whether user is assigned or not
         */
        ANY: () => "modules.appeals.*.*.lock.**",
        /**
         * Permission to toggle the case being locked for the user appealing
         * @param caseType The type of case
         * @param isAssigned Whether the user is assigned to this appeal or not
         * @param caseOwner Whether the user was the one that created the original case
         */
        TOGGLE_USER: (caseType, isAssigned, caseOwner) => {
            return `modules.appeals.${caseType}.${isAssigned === "*"
                ? "*"
                : isAssigned
                    ? "assigned"
                    : "unassigned"}.case.${IsResourceOwner(caseOwner)}.lock.user.update`;
        },
        /**
         * Permission to toggle the case being locked for all staff members. Staff members with same node can toggle it off.
         * @param caseType The type of case
         * @param isAssigned Whether the user is assigned to this appeal or not
         * @param caseOwner Whether the user was the one that created the original case
         */
        TOGGLE_STAFF: (caseType, isAssigned, caseOwner) => {
            return `modules.appeals.${caseType}.${isAssigned === "*"
                ? "*"
                : isAssigned
                    ? "assigned"
                    : "unassigned"}.case.${IsResourceOwner(caseOwner)}.lock.staff.update`;
        },
        /**
         * Permission to toggle disallowing user to create further appeals if the appeal was rejected
         * @param caseType The type of case
         * @param caseOwner Whether the user was the one that created the original case
         */
        FURTHER_APPEAL: (caseType, caseOwner) => {
            return `modules.appeals.${caseType}.case.${IsResourceOwner(caseOwner)}.lock.appeal.update`;
        },
    },
};
/**
 * Related to files uploaded to the appeal
 */
export const FILE = {
    /**
     * Permission to anything appeal file related, of any type of appeal, whether user is assigned or not
     */
    ANY: () => "modules.appeals.*.*.file.**",
    /**
     * Permission to read any file uploaded to the appeal
     */
    READ_ANY: () => {
        return "modules.appeals.*.*.file.read";
    },
    /**
     * Permission to read the files uploaded by staff members and the appealing user
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param {Omit<ResourceOwner, Wildcard>} caseOwner Whether the user was the one that created the original case
     */
    READ: (caseType, isAssigned, caseOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.file.read`;
    },
    /**
     * Permission to upload a new file to the case appeal
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param caseOwner Whether the user was the one that created the original case
     */
    CREATE: (caseType, isAssigned, caseOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.file.create`;
    },
    /**
     * Permission to delete a file uploaded to the case appeal
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param fileOwner Whether the user was the one that uploaded the file
     * @param caseOwner Whether the user was the one that created the original case
     */
    DELETE: (caseType, isAssigned, caseOwner, fileOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.file.${IsResourceOwner(fileOwner)}.delete`;
    },
};
/**
 * Related to the chat feature of appeals
 */
export const CHAT = {
    /**
     * Permission to anything appeal chat related, of any type of appeal, whether user is assigned or not
     */
    ANY: () => "modules.appeals.*.*.chat.**",
    /**
     * Permission to enable the chat feature for staff and the appealing user
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param caseOwner Whether the user was the one that created the original case
     */
    ENABLE: (caseType, isAssigned, caseOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.chat.create`;
    },
    /**
     * Permission to read the message sent by staff member and the appealing user
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param caseOwner Whether the user was the one that created the original case
     */
    READ: (caseType, isAssigned, caseOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.chat.message.read`;
    },
    /**
     * Permission to send messages in the case appeal, if chat is enabled
     * @param caseType The type of case
     * @param isAssigned Whether the user is assigned to this appeal or not
     * @param caseOwner Whether the user was the one that created the original case
     */
    CREATE_MESSAGE: (caseType, isAssigned, caseOwner) => {
        return `modules.appeals.${caseType}.${isAssigned === "*"
            ? "*"
            : isAssigned
                ? "assigned"
                : "unassigned"}.case.${IsResourceOwner(caseOwner)}.chat.message.create`;
    },
};
